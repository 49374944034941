// Add PickUpPoints
export const ADD_PICKUP_POINT = "ADD_PICKUP_POINT"
export const ADD_PICKUP_POINT_SUCCESS = "ADD_PICKUP_POINT_SUCCESS"
export const ADD_PICKUP_POINT_FAIL = "ADD_PICKUP_POINT_FAIL"

// Get PickUpPoints
export const GET_PICKUP_POINTS = "GET_PICKUP_POINTS"
export const GET_PICKUP_POINTS_SUCCESS = "GET_PICKUP_POINTS_SUCCESS"
export const GET_PICKUP_POINTS_FAIL = "GET_PICKUP_POINTS_FAIL"

// Update PickUpPoints
export const UPDATE_PICKUP_POINT = "UPDATE_PICKUP_POINT"
export const UPDATE_PICKUP_POINT_SUCCESS = "UPDATE_PICKUP_POINT_SUCCESS"
export const UPDATE_PICKUP_POINT_FAIL = "UPDATE_PICKUP_POINT_FAIL"

// Delete PickUpPoints
export const DELETE_PICKUP_POINT = "DELETE_PICKUP_POINT"
export const DELETE_PICKUP_POINT_SUCCESS = "DELETE_PICKUP_POINT_SUCCESS"
export const DELETE_PICKUP_POINT_FAIL = "DELETE_PICKUP_POINT_FAIL"

