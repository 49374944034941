

/* TOYS */
export const GET_CITIES = "GET_CITIES"
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS"
export const GET_CITIES_FAIL = "GET_CITIES_FAIL"

/**
 * add TOY
 */
export const ADD_NEW_CITY = "ADD_NEW_CITY"
export const ADD_CITY_SUCCESS = "ADD_CITY_SUCCESS"
export const ADD_CITY_FAIL = "ADD_CITY_FAIL"

/**
 * Edit TOY
 */
export const UPDATE_CITY = "UPDATE_CITY"
export const UPDATE_CITY_SUCCESS = "UPDATE_CITY_SUCCESS"
export const UPDATE_CITY_FAIL = "UPDATE_CITY_FAIL"

/**
 * Delete TOY
 */
export const DELETE_CITY = "DELETE_CITY"
export const DELETE_CITY_SUCCESS = "DELETE_CITY_SUCCESS"
export const DELETE_CITY_FAIL = "DELETE_CITY_FAIL"