// redux/orders/actionTypes.js
export const GET_ALL_ORDERS = "GET_ALL_ORDERS"
export const GET_ALL_ORDERS_SUCCESS = "GET_ALL_SUCCESS"
export const GET_ALL_ORDERS_FAIL = "GET_ALL_FAIL"

export const GET_UPCOMING_ORDERS = "GET_UPCOMING_ORDERS"
export const GET_UPCOMING_SUCCESS = "GET_UPCOMING_SUCCESS"
export const GET_UPCOMING_FAIL = "GET_UPCOMING_FAIL"

export const GET_ONGOING_ORDERS = "GET_ONGOING_ORDERS"
export const GET_ONGOING_SUCCESS = "GET_ONGOING_SUCCESS"
export const GET_ONGOING_FAIL = "GET_ONGOING_FAIL"

export const GET_DUE_RETURN_ORDERS = "GET_DUE_RETURN_ORDERS"
export const GET_DUE_RETURN_SUCCESS = "GET_DUE_RETURN_SUCCESS"
export const GET_DUE_RETURN_FAIL = "GET_DUE_RETURN_FAIL"

export const GET_PREBOOKING_ORDERS = "GET_PREBOOKING_ORDERS"
export const GET_PREBOOKING_SUCCESS = "GET_PREBOOKING_SUCCESS"
export const GET_PREBOOKING_FAIL = "GET_PREBOOKING_FAIL"

export const CHANGE_ORDER_STATUS = "CHANGE_ORDER_STATUS"
export const CHANGE_ORDER_STATUS_SUCCESS = "CHANGE_ORDER_STATUS_SUCCESS"
export const CHANGE_ORDER_STATUS_FAIL = "CHANGE_ORDER_STATUS_FAIL"

export const CANCEL_ORDER = "CANCEL_ORDER"
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS"
export const CANCEL_ORDER_FAIL = "CANCEL_ORDER_FAIL"

export const GET_REFUNDED_ORDERS = "GET_REFUNDED_ORDERS"
export const GET_REFUNDED_ORDERS_SUCCESS = "GET_REFUNDED_ORDERS_SUCCESS"
export const GET_REFUNDED_ORDERS_FAIL = "GET_REFUNDED_ORDERS_FAIL"

export const GET_CANCELLED_ORDERS = "GET_CANCELLED_ORDERS"
export const GET_CANCELLED_ORDERS_SUCCESS = "GET_CANCELLED_ORDERS_SUCCESS"
export const GET_CANCELLED_ORDERS_FAIL = "GET_CANCELLED_ORDERS_FAIL"

export const GET_COMPLETED_ORDERS = "GET_COMPLETED_ORDERS"
export const GET_COMPLETED_ORDERS_SUCCESS = "GET_COMPLETED_ORDERS_SUCCESS"
export const GET_COMPLETED_ORDERS_FAIL = "GET_COMPLETED_ORDERS_FAIL"
