import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_PAYMENT_HISTORY
} from "./actionTypes";
import {
  getPaymentHistorySuccess,
  getPaymentHistoryFail
} from "./actions";

import {
  getPaymentHistory
} from "helpers/payment_helper"


function* getPaymentHistoryFunc({ payload: { page, limit,startDate, endDate, query } }) {
  try {
    const response = yield call(getPaymentHistory, page,limit, startDate, endDate, query);
    yield put(getPaymentHistorySuccess(response));
  } catch (error) {
    yield put(getPaymentHistoryFail(error));
  }
}

function* categorySaga() {
  yield takeEvery(GET_PAYMENT_HISTORY, getPaymentHistoryFunc);
}

export default categorySaga;
