

/* TOYS */
export const GET_AGEGROUPS = "GET_AGEGROUPS"
export const GET_AGEGROUPS_SUCCESS = "GET_AGEGROUPS_SUCCESS"
export const GET_AGEGROUPS_FAIL = "GET_AGEGROUPS_FAIL"

/**
 * add TOY
 */
export const ADD_NEW_AGEGROUP = "ADD_NEW_AGEGROUP"
export const ADD_AGEGROUP_SUCCESS = "ADD_AGEGROUP_SUCCESS"
export const ADD_AGEGROUP_FAIL = "ADD_AGEGROUP_FAIL"

/**
 * Edit TOY
 */
export const UPDATE_AGEGROUP = "UPDATE_AGEGROUP"
export const UPDATE_AGEGROUP_SUCCESS = "UPDATE_AGEGROUP_SUCCESS"
export const UPDATE_AGEGROUP_FAIL = "UPDATE_AGEGROUP_FAIL"

/**
 * Delete TOY
 */
export const DELETE_AGEGROUP = "DELETE_AGEGROUP"
export const DELETE_AGEGROUP_SUCCESS = "DELETE_AGEGROUP_SUCCESS"
export const DELETE_AGEGROUP_FAIL = "DELETE_AGEGROUP_FAIL"