

/* TOYS */
export const GET_AREAS = "GET_AREAS"
export const GET_AREAS_SUCCESS = "GET_AREAS_SUCCESS"
export const GET_AREAS_FAIL = "GET_AREAS_FAIL"

/**
 * add TOY
 */
export const ADD_NEW_AREA = "ADD_NEW_AREA"
export const ADD_AREA_SUCCESS = "ADD_AREA_SUCCESS"
export const ADD_AREA_FAIL = "ADD_AREA_FAIL"

/**
 * Edit TOY
 */
export const UPDATE_AREA = "UPDATE_AREA"
export const UPDATE_AREA_SUCCESS = "UPDATE_AREA_SUCCESS"
export const UPDATE_AREA_FAIL = "UPDATE_AREA_FAIL"

/**
 * Delete TOY
 */
export const DELETE_AREA = "DELETE_AREA"
export const DELETE_AREA_SUCCESS = "DELETE_AREA_SUCCESS"
export const DELETE_AREA_FAIL = "DELETE_AREA_FAIL"