

/* CUSTOMER */
export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL"

/**
 * add CUSTOMER
 */
export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER"
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS"
export const ADD_CUSTOMER_FAIL = "ADD_CUSTOMER_FAIL"

/**
 * Edit CUSTOMER
 */
export const ON_UPDATE_CUSTOMER = "ON_UPDATE_CUSTOMER"
export const ON_UPDATE_CUSTOMER_SUCCESS = "ON_UPDATE_CUSTOMER_SUCCESS"
export const ON_UPDATE_CUSTOMER_FAIL = "ON_UPDATE_CUSTOMER_FAIL"

/**
 * Delete CUSTOMER
 */
export const ON_DELETE_CUSTOMER = "ON_DELETE_CUSTOMER"
export const ON_DELETE_CUSTOMER_SUCCESS = "ON_DELETE_CUSTOMER_SUCCESS"
export const ON_DELETE_CUSTOMER_FAIL = "ON_DELETE_CUSTOMER_FAIL"

//Individual Customer details.
export const GET_CUSTOMER_DETAILS = "GET_CUSTOMER_DETAILS";
export const GET_CUSTOMER_DETAILS_SUCCESS = "GET_CUSTOMER_DETAILS_SUCCESS";
export const GET_CUSTOMER_DETAILS_FAIL = "GET_CUSTOMER_DETAILS_FAIL";

//Customer Orders
export const GET_CUSTOMER_ORDERS = "GET_CUSTOMER_ORDERS";
export const GET_CUSTOMER_ORDERS_SUCCESS = "GET_CUSTOMER_ORDERS_SUCCESS";
export const GET_CUSTOMER_ORDERS_FAIL = "GET_CUSTOMER_ORDERS_FAIL";

// Customer Enquiries
export const GET_CUSTOMER_ENQUIRIES = "GET_CUSTOMER_ENQUIRIES";
export const GET_CUSTOMER_ENQUIRIES_SUCCESS = "GET_CUSTOMER_ENQUIRIES_SUCCESS";
export const GET_CUSTOMER_ENQUIRIES_FAIL = "GET_CUSTOMER_ENQUIRIES_FAIL";
