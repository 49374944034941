

/* TOYS */
export const GET_ZONES = "GET_ZONES"
export const GET_ZONES_SUCCESS = "GET_ZONES_SUCCESS"
export const GET_ZONES_FAIL = "GET_ZONES_FAIL"

/**
 * add TOY
 */
export const ADD_NEW_ZONE = "ADD_NEW_ZONE"
export const ADD_ZONE_SUCCESS = "ADD_ZONE_SUCCESS"
export const ADD_ZONE_FAIL = "ADD_ZONE_FAIL"

/**
 * Edit TOY
 */
export const UPDATE_ZONE = "UPDATE_ZONE"
export const UPDATE_ZONE_SUCCESS = "UPDATE_ZONE_SUCCESS"
export const UPDATE_ZONE_FAIL = "UPDATE_ZONE_FAIL"

/**
 * Delete TOY
 */
export const DELETE_ZONE = "DELETE_ZONE"
export const DELETE_ZONE_SUCCESS = "DELETE_ZONE_SUCCESS"
export const DELETE_ZONE_FAIL = "DELETE_ZONE_FAIL"