/** Get Dashboard Chart data */
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";
export const GET_CHARTS_DATA = "GET_CHARTS_DATA";
export const GET_DASHBOARD_COUNTS = "GET_DASHBOARD_COUNTS";
export const GET_DASHBOARD_COUNTS_SUCCESS = "GET_DASHBOARD_COUNTS_SUCCESS";
export const GET_DASHBOARD_COUNT_FAIL = "GET_DASHBOARD_COUNT_FAIL";

export const GET_DASHBOARD_TOP_RENTED_TOYS = "GET_DASHBOARD_TOP_RENTED_TOYS";
export const GET_DASHBOARD_TOP_RENTED_TOYS_SUCCESS = "GET_DASHBOARD_TOP_RENTED_TOYS_SUCCESS";
export const GET_DASHBOARD_TOP_RENTED_TOYS_FAIL = "GET_DASHBOARD_TOP_RENTED_TOYS_FAIL";