export const DeliveryDays = {
    SUNDAY: 0,
    MONDAY : 1,
    TUESDAY : 2,
    WEDNESDAY: 3,
    THURSDAY : 4, 
    FRIDAY: 5, 
    SATURDAY: 6
}

export const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const ORDER_STATUS = {
    'AVAILABLE':'AVAILABLE', 
    'RENTED': 'RENTED', 
    'ONHOLD': 'ONHOLD', 
    'CLEANING': 'CLEANING', 
    'BROKEN': 'BROKEN', 
    'MAINTENANCE':'MAINTENANCE'
}